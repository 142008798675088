import { observerOptions } from "../../constants/constants";
import { Container } from "../Container";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import "./Intro.css";
import { forwardRef } from "react";
import SmoothScrollbar from "smooth-scrollbar";

export const Intro = forwardRef((props, ref) => {
  const observer = useInView(observerOptions);
  const { contactRef, introRef, appRef } = ref;
  return (
    <section className="intro">
      <Container>
        <div className="intro__title__section">
          <div className={classNames("intro__subheading", { subheadingAnim: observer.inView })}>
            Who We Are and
            <br />
            What We Do?
          </div>
          <div className={classNames("intro__heading", { headingAnim: observer.inView })} ref={introRef}>
            Allow <span className="lightItalic">Us</span> to Make an
            <br />
            <span className="lightItalic">Introduction</span>
          </div>
        </div>
        <div className="intro__text__container">
          <p className={classNames("intro__text", { introTextAnim: observer.inView })}>
            Our team is united by a shared passion for producing exceptional digital products and presenting them in a
            compelling way to users around the world. We specialize in the following areas:
          </p>
        </div>
        <div className="intro__row" ref={observer.ref}>
          <div className={classNames("intro__row__item", { introRowItemAnim: observer.inView })}>
            <div className="intro__row__item__inner">
              <svg
                className="intro__row__item__icon"
                width="30"
                height="116"
                viewBox="0 0 30 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.296 11.04V0.379993H29.652V116H17.68V11.04H0.296Z" fill="white" />
              </svg>

              <div className="intro__row__item__info">
                <div className="intro__row__item__info__title">
                  Digital and Web
                  <br /> Development
                </div>
                <div className="intro__row__item__info__text">
                  We offer services for the development, optimization, refinement, and maintenance of digital products
                  designed for both web and mobile devices.
                </div>
              </div>
            </div>
          </div>
          <div className={classNames("intro__row__item", { introRowItemAnim: observer.inView })}>
            <div className="intro__row__item__inner">
              <svg
                className="intro__row__item__icon"
                width="79"
                height="118"
                viewBox="0 0 79 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.38 109.308C19.732 96.516 35.148 83.6693 46.628 70.768C58.2173 57.8667 64.012 45.7853 64.012 34.524C64.012 27.4173 62.1533 21.6773 58.436 17.304C54.7187 12.8213 48.7053 10.58 40.396 10.58C31.868 10.58 25.3627 13.2587 20.88 18.616C16.5067 23.864 14.0467 31.0253 13.5 40.1H2.184C2.94933 27.5267 6.83067 17.8507 13.828 11.072C20.8253 4.184 29.9547 0.739995 41.216 0.739995C51.4933 0.739995 59.8027 3.52799 66.144 9.10399C72.4853 14.68 75.656 22.8253 75.656 33.54C75.656 42.6147 72.6493 51.908 66.636 61.42C60.6227 70.8227 53.1333 79.5693 44.168 87.66C35.2027 95.6413 26.0733 102.42 16.78 107.996H78.936V117.508H0.38V109.308Z"
                  fill="white"
                />
              </svg>

              <div className="intro__row__item__info">
                <div className="intro__row__item__info__title">Marketing Services</div>
                <div className="intro__row__item__info__text">
                  In addition, we specialize in promiting products in the digital space, analyzing and identifying
                  target audiences, and implementing effective advertising monetization strategies
                </div>
              </div>
            </div>
          </div>
          <div className="intro__row__button__container">
            <div
              className={classNames("intro__row__button", {
                introRowButtonAnim: observer.inView,
              })}
              onClick={() => SmoothScrollbar.get(appRef.current).scrollTo(0, contactRef.current.offsetTop - 150, 700)}
            >
              Contact us
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
});
