import "./PrivacyPolicy.css";

export const PrivacyPolicy = ({ handleClick }) => {
  return (
    <div className="privacy">
      <div className="privacy__inner">
        <div className="privacy__inner__cross" onClick={handleClick}>
          <div className="privacy__inner__cross__line"></div>
          <div className="privacy__inner__cross__line"></div>
        </div>
        <div>
          {/* <h1>Privacy Policy</h1>

          <h2>As at 07/2023</h2>

          <p>
            NEXGEN TECHNOLOGIES LIMITED is a UK web development and marketing agency delivering marketing, creative, and
            digital solutions to drive business success. We are committed to protecting and respecting any personal
            information you share with us. This Notice describes the types of personal information we collect from you
            when you work with us, use our website, interact with our digital platforms, when we meet you, and when you
            contact us. It explains how your personal information is used by us, when we share it with others, and your
            choices about its use.
          </p>

          <p>
            Where this statement refers to "we", "our", or "us" below, unless it mentions otherwise, we’re referring to
            NEXGEN TECHNOLOGIES LIMITED. Our registered address is Office 4 219 Kensington High Street, London, England,
            W86BD. We may update this statement from time to time, so you may wish to check this Notice occasionally. If
            any significant changes are made to the way in which we handle and use your personal information, we will
            endeavor to notify you directly.
          </p>

          <h2>HOW TO CONTACT US</h2>

          <p>
            Please direct any queries about this Notice or about the way we process your personal information to our
            Data Protection Champion using the postal address above or by emailing us at{" "}
            <a href="mailto:ceo@nexgen-technologies.com">ceo@nexgen-technologies.com</a>. If you would prefer to speak
            to us by phone, please call <a href="tel:+447441394639">+447441394639</a>.
          </p>

          <h2>HOW WE COLLECT YOUR PERSONAL INFORMATION</h2>

          <p>
            We may collect information about you whenever you engage and interact with us, whether through our website{" "}
            <a href="nexgen-technologies.com/">nexgen-technologies.com/</a>, over the telephone, face to face, email,
            social media, or other touch points. We may also obtain information about you when you contact us, register
            for events, if you complete any of our surveys conducted for research purposes, or if you apply for a job.
          </p>

          <p>The personal information we may collect routinely includes the following:</p>

          <ul>
            <li>Full name</li>
            <li>Email address</li>
            <li>Mobile number</li>
            <li>Any information you provide as part of the content of a message</li>
          </ul>

          <p>
            Where we believe you are likely to be interested in our services, we may also obtain your name and business
            contact details from publicly available sources (for example, information you have allowed to be shared as
            part of your public profile). We will also handle personal information about you if you include personal
            details within correspondence you send to us.
          </p>

          <p>
            In providing our services, we do not routinely handle any Special Category Data, and our services are not
            aimed at children. If you are applying for a job with NEXGEN TECHNOLOGIES LIMITED, you should reference our
            Applicant Privacy Notice (available via our website or on request). We also have a specific privacy notice
            for our employees.
          </p>

          <h2>HOW WE USE YOUR PERSONAL INFORMATION</h2>

          <p>
            We endeavor to only handle the personal information necessary for our business operations. Outlined below
            are the ways in which we use your personal information and details of our lawful basis for processing under
            UK GDPR.
          </p>

          <h3>To send you marketing communications</h3>

          <p>
            We want to keep in touch with you to provide information about our services and promise we will never sell
            your information to other organizations for their own marketing purposes. We rely on our legitimate
            interests to send you marketing communications in a business context. We will always give you the option not
            to receive marketing communications from us in the future.
          </p>

          <p>
            We include tracking pixels or web beacons in our email newsletters so we can track how many people open our
            emails and click to view certain pages on our website. They help us to understand the effectiveness of our
            email communications. We may contact you with targeted advertising delivered online through social media and
            platforms operated by other companies or use your personal information to tailor marketing to improve its
            relevance to you, unless you object.
          </p>

          <p>
            You can stop receiving marketing from us at any time by using the unsubscribe mechanism on our emails or by
            contacting us at <a href="mailto:ceo@nexgen-technologies.com">ceo@nexgen-technologies.com</a>. If you would
            prefer to speak to us by phone, please call <a href="tel:+447441394639">+447441394639</a>.
          </p>

          <h3>To provide our services</h3>

          <p>
            We will handle your personal information where we supply to you, have supplied to you, or are in discussions
            about supplying any of our services, or where we have arranged for the supply of another company's products
            or services to you. We will use this information in connection with providing our services. This will
            routinely include names and business contact information. We process this personal data under the lawful
            basis of performance of a contract or necessary for taking steps before entering into a contract.
          </p>

          <h3>Where we have a legal obligation</h3>

          <p>
            It may be necessary for us to process your personal information to comply with legal obligations. For
            example, for taxation purposes, fulfilling employment law requirements, or other statutory regulations.
          </p>

          <h3>Where we have a legitimate interest</h3>

          <p>
            We will use and process your personal information where it is necessary for us to pursue our legitimate
            interests as a business. Where we rely on legitimate interests, we balance our interests with yours and make
            sure your privacy rights and freedoms are protected.
          </p>

          <p>We rely on our legitimate interests for the following purposes:</p>

          <ul>
            <li>
              To conduct market analysis and research (including contacting you with surveys) so we can better
              understand your needs and provide tailored and bespoke offers and services that we think you will be
              interested in
            </li>
            <li>
              For service and product development purposes (for example, to improve our services, offering, and
              performance)
            </li>
            <li>
              To administer competitions and promotions that you enter with us from time to time and to distribute
              prizes
            </li>
            <li>
              To analyze, evaluate, and improve our services so that your engagement with us is more useful and
              enjoyable
            </li>
            <li>To correspond or communicate with you, where not strictly necessary as part of a contract</li>
            <li>To verify the accuracy of data we hold about you</li>
            <li>For network and information security</li>
            <li>For prevention of fraud and other criminal activities</li>
            <li>For the management of queries, complaints, or claims</li>
            <li>For the establishment and defense of our legal rights</li>
            <li>To inform you of updates to our terms and conditions and policies</li>
          </ul>

          <p>
            In exceptional circumstances, we may handle your personal information for reasons of public interests or to
            protect your vital interests.
          </p>

          <h2>YOUR RIGHTS</h2>

          <p>
            In addition to your right to not receive marketing (please see above 'To send you marketing
            communications'), you have other privacy rights under data protection law. To fulfill these rights, we may
            need to ask you to provide proof of your identity. We will acknowledge any request received and provide
            further information, as necessary. Your rights are:
          </p>

          <h3>Accessing your personal information</h3>

          <p>
            You have the right to ask for a copy of your 'personal data' (as defined under UK GDPR) and details of the
            purpose(s) we are using it for.
          </p>

          <h3>Correcting and updating your personal information</h3>

          <p>You have the right to request that we amend any personal data that is incorrect or requires updating.</p>

          <h3>Objecting to our use of your personal information</h3>

          <p>
            In certain circumstances, you have the right to object to our use of your personal information. For example,
            when we rely on legitimate interests for processing your personal data, you may object to these purposes.
            Any objection received will be assessed and handled on a case-by-case basis.
          </p>

          <h3>Erasing your personal information or restricting its use</h3>

          <p>
            You can ask for your personal information to be removed from our systems. This is not an automatic right,
            and there may be circumstances in which we can justifiably deny your request. You may also ask us to
            restrict our use of your personal information. For example, where you believe it is unlawful for us to do
            so, you have objected to its use and our investigation is pending, or you require us to keep it in
            connection with legal proceedings.
          </p>

          <h3>Transferring your personal information in a structured data file ("data portability")</h3>

          <p>
            In specific circumstances, you may ask us to provide you or another organization with a copy of your
            personal information in a structured file.
          </p>

          <p>
            If you wish to exercise any of the above rights, please contact our Data Protection Champion:
            <br />
            Email: <a href="mailto:ceo@nexgen-technologies.com">ceo@nexgen-technologies.com</a>
            <br />
            Telephone: <a href="tel:+447441394639">+447441394639</a>
            <br />
            Address: Office 4 219 Kensington High Street, London, England, W86BD.
          </p>

          <p>
            We make every effort to fully respond to any queries or specific requests, and please let us know if you
            feel we have not responded sufficiently. If you are not satisfied with the way in which we have handled your
            request, you have the right to complain to the Information Commissioner’s Office (ICO) if you are concerned
            about the way we have processed your personal information. Please visit the{" "}
            <a href="https://ico.org.uk/">ICO’s website</a> for further details.
          </p>

          <h2>OTHERS WHO MAY RECEIVE OR HAVE ACCESS TO YOUR PERSONAL INFORMATION</h2>

          <p>
            <strong>Our suppliers and service providers:</strong>
            <br />
            We will share your information (or give access to it), where necessary, with our service providers, agents,
            subcontractors, and other organizations who act on our behalf to provide our services. Such organizations
            may include cloud service providers (such as hosting and email providers), advertising media companies,
            photographers, printers, solicitors, accountants, advisors, and administrative services. When we use service
            providers, we limit the personal information shared to what is necessary for them to provide their service.
            We have agreements in place with our service providers requiring them to keep your information secure and
            not to use it other than in accordance with our specific instructions.
          </p>

          <p>
            <strong>Other ways we may share your personal information:</strong>
            <br />
            We may transfer your personal information to another organization as part of a sale of some or all of our
            business and assets or as part of any business restructuring or reorganization. We may also disclose your
            personal information if we are under a duty to do so in order to comply with any legal obligation, to detect
            or report a crime, to enforce or apply the terms of our contracts, or to protect the rights, property, or
            safety of our visitors and customers. We will always take steps with the aim of ensuring that your privacy
            rights continue to be protected.
          </p>

          <h2>WHERE WE STORE YOUR PERSONAL INFORMATION</h2>

          <p>
            All information you provide to us is stored on our secure servers located in the UK. If at any time we
            transfer your personal information to or store it in countries located outside the UK (for example, if our
            hosting services provider changes), we will endeavor to make sure appropriate safeguards are in place for
            that transfer and storage as necessary and if required under applicable law. Where we use service providers
            based outside the UK, we endeavor to make sure this is appropriately covered by appropriate safeguards under
            UK data protection law.
          </p>

          <h2>HOW LONG WE KEEP YOUR PERSONAL INFORMATION FOR</h2>

          <p>
            The length of time we keep your personal information for is determined by a number of factors, including the
            purpose for which we use that information, our business needs, and in some cases, our obligations under
            other laws. We take necessary steps and endeavor to only keep your personal information for as long as is
            necessary for the purpose(s) we are handling it. If you would like more information concerning our retention
            periods, please email <a href="mailto:ceo@nexgen-technologies.com">ceo@nexgen-technologies.com</a>. If you'd
            prefer to speak to us, please call <a href="tel:+447441394639">+447441394639</a>.
          </p>

          <h2>OUR WEBSITE & LINKS TO OTHER SITES</h2>

          <p>
            Unfortunately, the transmission of information via the internet is not completely secure. Although we will
            do our best to protect your personal information, we cannot guarantee the security of your information when
            and if transmitted to our website and, accordingly, any transmission is at your own risk. Once we have
            received your personal information, we put in place reasonable and appropriate controls to ensure that it
            remains secure against accidental or unlawful destruction, loss, alteration, or unauthorized access.
          </p>

          <p>
            Our website may contain links to other websites run by other organizations. This Notice does not apply to
            those other websites, so we encourage you to read their Privacy Notices. We cannot be responsible for the
            privacy policies and practices of other websites even if you access them using links that we provide. In
            addition, if you linked to our website from a third-party website, we cannot be responsible for the privacy
            policies and practices of the owners and operators of that third-party website and recommend that you check
            the Privacy Notice of that third-party website.
          </p> */}
          <h1>PRIVACY POLICY</h1>
          <p>
            This privacy policy explains how we use and protect any and all personal and customer information we hold,
            whether it relates to our customers, potential customers, job candidates, partners, or third party service
            providers.
          </p>

          <h2>Our Commitment to Privacy</h2>
          <p>
            NEXGEN TECHNOLOGIES LIMITED is committed to conducting its business ethically in line with all applicable
            Data Protection laws and regulations. We take responsibility for maintaining and protecting the customer
            information that we hold. We review this policy and our internal data protection policy and practices on a
            regular basis to ensure that we are in compliance with applicable legislation and current best practices.
          </p>

          <h2>What we collect</h2>
          <p>We may collect the following information:</p>
          <ul>
            <li>Name and job title;</li>
            <li>Business information including postal and billing addresses and website address;</li>
            <li>Contact information including email address and phone number;</li>
            <li>Banking details;</li>
            <li>Demographic information such as postcode, preferences and interests;</li>
            <li>Cookie information (explained in more detail below);</li>
            <li>
              Other information relevant to a project that we may supply a quote for or information relevant to managing
              a project that we are contracted to work with you on.
            </li>
          </ul>
          <p>We collect customer information for one or more of the following purposes:</p>
          <ul>
            <li>To provide the service we have been contracted to provide;</li>
            <li>
              To maintain customer relationships, communicate with our customers, and avoid conflicts of interest;
            </li>
            <li>
              To understand customer requirements and make information available regarding products and services offered
              by NEXGEN TECHNOLOGIES LIMITED;
            </li>
            <li>To contact you in response to the communications you have directed at us;</li>
            <li>To manage and develop NEXGEN TECHNOLOGIES LIMITED’ business and operations;</li>
            <li>To meet legal and regulatory requirements;</li>
            <li>To gauge website user interest and to enhance future website user experiences.</li>
          </ul>

          <h2>Why do we process your information?</h2>
          <p>
            We will only collect and use your personal information in accordance with data protection laws. Our grounds
            for processing your personal information are as follows:
          </p>
          <ul>
            <li>
              Consent – Where necessary we will only collect and process your personal information if you have consented
              for us to do so.
            </li>
            <li>
              Legitimate Interests – We may use and process some of your personal information where we have sensible and
              legitimate business grounds for doing so. Under European privacy laws there is a concept of “legitimate
              interests” as a justification for processing your personal information. Our legitimate interests for
              processing your personal information are:
            </li>
            <ul>
              <li>
                to enable you to access and use our website and other digital tools we may provide as part of our
                service;
              </li>
              <li>
                to communicate with you about our service or make information available regarding products and services
                offered by NEXGEN TECHNOLOGIES LIMITED;
              </li>
              <li>to improve our website.</li>
            </ul>
          </ul>

          <h2>Controlling your information</h2>
          <p>
            All individuals who are the subject of personal data held by NEXGEN TECHNOLOGIES LIMITED are entitled to:
          </p>
          <ul>
            <li>Ask what information the company holds about them</li>
            <li>Ask what the purpose of the data is</li>
            <li>Receive a copy of the data</li>
            <li>Be informed how to keep it up to date</li>
            <li>Object to the use of the data</li>
            <li>Request the data is corrected, rectified, restricted, moved, or erased</li>
          </ul>
          <p>
            Subject access requests must be made in writing to the London office address, for the attention of Data
            Request Team or by email: info@nexgen-technologies.com.
          </p>
          <p>
            The subject access request will be responded to within 40 days. The company will require proof of
            identification to verify the subject access request before handing over any information.
          </p>

          <h2>Data Security and Integrity</h2>
          <p>
            Keeping information about you secure is very important to us so we store and process your personal
            information in accordance with the high standards required under data protection legislation. We only
            collect customer information as required to meet the purposes that we have identified. We strive to maintain
            customer information in as accurate, complete and up-to-date a form as necessary to fulfil the purposes for
            which it was collected.
          </p>
          <p>
            To the best of our ability, access to customer information is limited to those who have a need to know.
            Those individuals who have access to the data are required to maintain the confidentiality of such
            information. We also make reasonable efforts to retain customer information only for so long as the
            information is necessary to comply with a customer request.
          </p>
          <p>
            We do our best to keep the information you disclose to us secure. However, security cannot be absolutely
            guaranteed against all threats. By using our service and website you accept the inherent risks of providing
            information online and will not hold us responsible for any breach of security.
          </p>

          <h2>Changes to this policy</h2>
          <p>
            NEXGEN TECHNOLOGIES LIMITED may modify this Privacy Statement from time to time to reflect our current
            privacy practices. You should check this page from time to time to ensure that you are happy with any
            changes.
          </p>
        </div>
      </div>
    </div>
  );
};
