import { forwardRef, useEffect, useRef, useState } from "react";
import "./Header.css";
import classNames from "classnames";
import SmoothScrollbar from "smooth-scrollbar";

export const Header = forwardRef((props, ref) => {
  const { appRef, introRef, productsRef, areasRef, servicesRef, contactRef } = ref;
  const [isTransparent, setTransparentStatus] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const menuRef = useRef();
  const handleAboutLinkClick = (e) => {
    setTransparentStatus(false);
    const scrollbar = SmoothScrollbar.get(appRef.current);
    scrollbar.scrollTo(0, introRef.current.offsetTop - 150, 700);
  };

  const handleDevelopmentLinkClick = (e) => {
    setTransparentStatus(false);
    const scrollbar = SmoothScrollbar.get(appRef.current);
    scrollbar.scrollTo(0, productsRef.current.offsetTop - 150, 700);
  };

  const handleMarketingLinkClick = (e) => {
    setTransparentStatus(false);
    const scrollbar = SmoothScrollbar.get(appRef.current);
    scrollbar.scrollTo(0, areasRef.current.offsetTop - 150, 700);
  };

  const handleConsultationsLinkClick = (e) => {
    setTransparentStatus(false);
    const scrollbar = SmoothScrollbar.get(appRef.current);
    scrollbar.scrollTo(0, servicesRef.current.offsetTop - 150, 700);
  };
  const handleContactLinkClick = (e) => {
    setTransparentStatus(false);
    const scrollbar = SmoothScrollbar.get(appRef.current);
    scrollbar.scrollTo(0, contactRef.current.offsetTop - 150, 700);
  };

  const handleCloseMenu = () => {
    if (window.innerWidth > 1020) {
      setMenuVisibility(false);
      setTransparentStatus(false);
    }
  };

  const handleScroll = (e) => {
    const scrollbar = SmoothScrollbar.get(appRef.current);
    if (window.innerWidth > 1020) {
      if (scrollbar.scrollTop > 100) {
        setTransparentStatus(false);
      } else {
        setTransparentStatus(true);
      }

      if (e.deltaY > 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setTransparentStatus(false);
      setMenuVisibility(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1020) {
      setTransparentStatus(false);
    }
    window.addEventListener("resize", handleCloseMenu);
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("resize", handleCloseMenu);
    };
  }, []);

  return (
    <>
      <header className={classNames("header", { transparent: isTransparent }, { visible: isVisible })}>
        <div className="header__inner">
          <div className="header__logo" onClick={() => SmoothScrollbar.get(appRef.current).scrollTo(0, 0, 700)}>
            <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.92438 24.5295C4.18898 20.8622 3.74644 13.8717 7.98158 9.63659L8.97971 8.63845C13.6227 3.99544 21.2475 4.33088 25.4648 9.36371L26.169 10.2041C30.3028 15.1372 34.8626 19.6971 39.7958 23.8309L40.5959 24.5014C45.6504 28.7368 45.9873 36.3943 41.3243 41.0573L40.8905 41.4911C36.3628 46.0188 28.8974 45.5776 24.9345 40.548L24.4236 39.8995C19.9037 34.1629 14.6986 29.0012 8.92438 24.5295Z"
                fill="#1B1B1B"
              />
              <circle cx="42.7148" cy="12.3811" r="7.42869" fill="#1B1B1B" />
              <circle cx="7.42869" cy="37.4529" r="7.42869" fill="#1B1B1B" />
            </svg>
          </div>
          <nav className="header__nav">
            <div className="header__link hoverUnderline" onClick={handleAboutLinkClick}>
              About Us
            </div>
            <div className="header__link hoverUnderline" onClick={handleDevelopmentLinkClick}>
              Development
            </div>
            <div className="header__link hoverUnderline" onClick={handleMarketingLinkClick}>
              Marketing
            </div>
            <div className="header__link hoverUnderline" onClick={handleConsultationsLinkClick}>
              Consultations
            </div>
          </nav>
          <div className="header__button buttonHover" onClick={handleContactLinkClick}>
            Contact us
          </div>
          <div className="header__menu__container">
            <div
              onClick={() => setMenuVisibility(!isMenuVisible)}
              className={classNames("header__menu", { menuIconTrasform: isMenuVisible })}
            >
              <div className="header__menu__line"></div>
              <div className="header__menu__line"></div>
              <div className="header__menu__line"></div>
            </div>
          </div>
        </div>
      </header>
      <div className={classNames("menu", { menuVisible: isMenuVisible })} ref={menuRef}>
        <div className="header__link" onClick={handleAboutLinkClick}>
          About Us
        </div>
        <div className="header__link" onClick={handleDevelopmentLinkClick}>
          Development
        </div>
        <div className="header__link" onClick={handleMarketingLinkClick}>
          Marketing
        </div>
        <div className="header__link" onClick={handleConsultationsLinkClick}>
          Consultations
        </div>
        <div className="header__button buttonHover" onClick={handleContactLinkClick}>
          Contact us
        </div>
      </div>
    </>
  );
});
