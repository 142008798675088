import uniqueWebProjects from "../../assets/uniqueWebProjects.png";
import modernDesignsAndInterfaces from "../../assets/modernDesignsAndInterfaces.png";
import creationOfMavelousMobileApps from "../../assets/creationOfMavelousMobileApps.png";
import { Container } from "../Container";
import { useInView } from "react-intersection-observer";
import { observerOptions } from "../../constants/constants";
import classNames from "classnames";
import { forwardRef } from "react";
import SmoothScrollbar from "smooth-scrollbar";
import "./Products.css";

export const Products = forwardRef((props, ref) => {
  const observer = useInView(observerOptions);
  const { contactRef, appRef, productsRef } = ref;
  return (
    <section className="products" ref={productsRef}>
      <Container>
        <div className="products__title__section">
          <div
            className={classNames("products__heading", {
              headingAnimInverted: observer.inView,
            })}
          >
            Leveraging <span className="lightItalic">Technology</span> to&nbsp;
            <br />
            Implement <span className="lightItalic">Digital Products</span>
          </div>
          <div className={classNames("products__subheading", { subheadingAnimInverted: observer.inView })}>
            What We
            <br /> Create?
          </div>
        </div>
      </Container>
      <div className="products__row" ref={observer.ref}>
        <Container>
          <div className="products__row__inner">
            <div className={classNames("products__column", { productsColumnAnim: observer.inView })}>
              <div className="products__column__image__container">
                <img src={uniqueWebProjects} alt="uniqueWebProjects" className="products__column__image" />
              </div>
              <div className="products__column__info">
                <div className="products__column__info__title">
                  Creating Distinctive
                  <br /> Web Projects
                </div>
                <div className="products__column__info__text">
                  Our team possesses the proficiency to develop custom web projects across a wide range of domains,
                  starting from scratch.
                </div>
              </div>
            </div>
            <div className={classNames("products__column", { productsColumnAnim: observer.inView })}>
              <div className="products__column__image__container">
                <img
                  src={modernDesignsAndInterfaces}
                  alt="modernDesignsAndInterfaces"
                  className="products__column__image"
                />
              </div>
              <div className="products__column__info">
                <div className="products__column__info__title">
                  Contemporary Designs and
                  <br /> Interfaces
                </div>
                <div className="products__column__info__text">
                  With our expertise, we specialize in building web projects from the ground up, spanning diverse
                  domains, while incorporating modern designs and interfaces.
                </div>
              </div>
            </div>
            <div className={classNames("products__column", { productsColumnAnim: observer.inView })}>
              <div className="products__column__image__container">
                <img
                  src={creationOfMavelousMobileApps}
                  alt="creationOfMavelousMobileApps"
                  className="products__column__image"
                />
              </div>
              <div className="products__column__info">
                <div className="products__column__info__title">
                  Creating Extraordinary
                  <br />
                  Mobile Apps
                </div>
                <div className="products__column__info__text">
                  Our team possesses exceptional skills in developing cross-platform and hybrid mobile applications,
                  resulting in stunning and innovative solutions.
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div
          className="products__button__container"
          onClick={() => SmoothScrollbar.get(appRef.current).scrollTo(0, contactRef.current.offsetTop - 150, 700)}
        >
          <div className="products__button buttonHover">Contact us</div>
        </div>
      </div>
    </section>
  );
});
