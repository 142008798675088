import { Container } from "../Container";
import servicesBg from "../../assets/Rectangle 121 (4).png";
import { observerOptions } from "../../constants/constants";
import { useInView } from "react-intersection-observer";
import { forwardRef } from "react";
import classNames from "classnames";
import "./Services.css";
import SmoothScrollbar from "smooth-scrollbar";

export const Services = forwardRef((props, ref) => {
  const observer = useInView(observerOptions);
  const { servicesRef, appRef, contactRef } = ref;
  return (
    <section className="services" ref={servicesRef}>
      <Container>
        <div className="services__title__section">
          <div className={classNames("services__heading", { headingAnimInverted: observer.inView })}>
            Comprehensive{" "}
            <span className="lightItalic">
              Array of
              <br /> Services
            </span>
            &nbsp;for&nbsp;
            <span className="lightItalic">
              Project
              <br /> Promotion
            </span>
          </div>
        </div>
      </Container>
      <div className="services__row" ref={observer.ref}>
        <Container>
          <div className="services__row__inner">
            <div className="services__row__image__container">
              <img
                src={servicesBg}
                className={classNames("services__row__image", { servicesRowImageAnim: observer.inView })}
                alt="serviceBg"
              />
            </div>
            <div className="services__row__info">
              <div className="services__row__info__text">
                Our team specializes in various{" "}
                <span className="lightItalic" style={{ color: "#8E97FF" }}>
                  marketing&nbsp;
                </span>
                and{" "}
                <span className="lightItalic" style={{ color: "#FF8A01" }}>
                  promotion areas&nbsp;
                </span>
                <br />
                <br /> Collaborating closely with you, our experts will create a{" "}
                <span className="lightItalic" style={{ color: "#78E37E" }}>
                  highly profitable advertising campaign specifically designed
                </span>{" "}
                to meet your unique requirements!
              </div>
              <div className="services__row__blobs">
                <div className="services__row__blob">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 500"
                    width="100%"
                    id="blobSvg"
                    style={{ opacity: 1 }}
                    className="services__row__blob__figure"
                  >
                    <path id="blob" fill="none" stroke="white" stroke-width="2">
                      <animate
                        attributeName="d"
                        dur="24000ms"
                        repeatCount="indefinite"
                        values="M446.86448,329.36764Q408.73529,408.73529,329.36764,419.76576Q250,430.79624,166.60504,423.79308Q83.21008,416.78992,69.36975,333.39496Q55.52942,250,96.13341,193.3687Q136.7374,136.7374,193.3687,119.10083Q250,101.46426,313.50105,112.23108Q377.00211,122.99789,430.99789,186.49895Q484.99368,250,446.86448,329.36764Z;M395.5,320Q390,390,320,400Q250,410,172,408Q94,406,59,328Q24,250,70.5,183.5Q117,117,183.5,108Q250,99,335,89.5Q420,80,410.5,165Q401,250,395.5,320Z;M418.08664,320.33435Q390.6687,390.6687,320.33435,427.91946Q250,465.17023,188.27506,419.31005Q126.55013,373.44987,106.38448,311.72494Q86.21883,250,84.09726,165.98785Q81.9757,81.9757,165.98785,53.98938Q250,26.00305,311.1687,76.83282Q372.3374,127.6626,408.92099,188.8313Q445.50458,250,418.08664,320.33435Z;M446.86448,329.36764Q408.73529,408.73529,329.36764,419.76576Q250,430.79624,166.60504,423.79308Q83.21008,416.78992,69.36975,333.39496Q55.52942,250,96.13341,193.3687Q136.7374,136.7374,193.3687,119.10083Q250,101.46426,313.50105,112.23108Q377.00211,122.99789,430.99789,186.49895Q484.99368,250,446.86448,329.36764Z"
                      ></animate>
                    </path>
                  </svg>

                  <div className="services__row__blob__number">95%</div>
                  <div className="services__row__blob__text">Experience of specialists</div>
                </div>

                <div className="services__row__blob">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 500"
                    width="100%"
                    id="blobSvg"
                    style={{ opacity: 1 }}
                    className="services__row__blob__figure"
                  >
                    <path id="blob" fill="none" stroke="white" stroke-width="2">
                      <animate
                        attributeName="d"
                        dur="15s"
                        repeatCount="indefinite"
                        values="M425.57934,323.79599Q426.22734,397.59197,355.10104,419.41516Q283.97474,441.23834,222.29242,423.00907Q160.6101,404.77979,95.49644,367.33031Q30.38277,329.88083,44.58128,255.13536Q58.77979,180.3899,99.7419,120.41159Q140.70401,60.43329,214.57221,45.49287Q288.44042,30.55246,350.61367,72.29792Q412.78692,114.04339,418.85914,182.0217Q424.93135,250,425.57934,323.79599Z;M443.46373,322.92581Q424.06453,395.85162,352.29275,408.54192Q280.52096,421.23222,209.43547,432.55323Q138.34998,443.87423,108.93547,378.01696Q79.52096,312.15968,50.50966,239.62741Q21.49835,167.09515,80.22256,112.52096Q138.94677,57.94677,211.78709,57.03792Q284.62741,56.12906,342.97339,89.43146Q401.31936,122.73387,432.09114,186.36693Q462.86293,250,443.46373,322.92581Z;M433.0232,309.9192Q393.01281,369.83841,338.3352,406.40881Q283.6576,442.97921,221.7752,424.49281Q159.8928,406.0064,119.2384,358.9864Q78.584,311.9664,70.5304,246.7752Q62.4768,181.584,97.7552,114.4464Q133.0336,47.3088,206.208,64.61119Q279.3824,81.91359,342.208,100.8192Q405.0336,119.7248,439.0336,184.8624Q473.0336,250,433.0232,309.9192Z;M425.57934,323.79599Q426.22734,397.59197,355.10104,419.41516Q283.97474,441.23834,222.29242,423.00907Q160.6101,404.77979,95.49644,367.33031Q30.38277,329.88083,44.58128,255.13536Q58.77979,180.3899,99.7419,120.41159Q140.70401,60.43329,214.57221,45.49287Q288.44042,30.55246,350.61367,72.29792Q412.78692,114.04339,418.85914,182.0217Q424.93135,250,425.57934,323.79599Z"
                      ></animate>
                    </path>
                  </svg>
                  <div className="services__row__blob__number">77%</div>
                  <div className="services__row__blob__text">Advertising campaign monetization</div>
                </div>
                <div className="services__row__blob">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 500"
                    width="100%"
                    id="blobSvg"
                    style={{ opacity: 1 }}
                    className="services__row__blob__figure"
                  >
                    <path id="blob" fill="none" stroke="white" stroke-width="2">
                      <animate
                        attributeName="d"
                        dur="15s"
                        repeatCount="indefinite"
                        values="M411.71818,339.87391Q393.61186,429.74783,302.23794,426.16285Q210.86403,422.57787,122.32727,388.31581Q33.79051,354.05375,61.55534,263.35692Q89.32016,172.66008,145.95613,107.46324Q202.59209,42.2664,289.95613,66.5581Q377.32016,90.8498,403.57233,170.4249Q429.82451,250,411.71818,339.87391Z;M384.02639,318.52969Q359.04619,387.05939,278.02474,435.01815Q197.0033,482.97691,137.94391,408.01485Q78.88453,333.05279,75.91422,249.0132Q72.94391,164.97361,138.46041,107.43731Q203.97691,49.90102,276.0132,88.41917Q348.04949,126.93731,378.52804,188.46866Q409.0066,250,384.02639,318.52969Z;M392.2525,331.53996Q379.05994,413.07992,285.7525,456.15485Q192.44505,499.22978,100.80019,432.44731Q9.15533,365.66484,30.81018,260.57493Q52.46503,155.48501,121.45005,74.10764Q190.43506,-7.26974,268.13261,60.97003Q345.83016,129.2098,375.63761,189.6049Q405.44505,250,392.2525,331.53996Z;M411.71818,339.87391Q393.61186,429.74783,302.23794,426.16285Q210.86403,422.57787,122.32727,388.31581Q33.79051,354.05375,61.55534,263.35692Q89.32016,172.66008,145.95613,107.46324Q202.59209,42.2664,289.95613,66.5581Q377.32016,90.8498,403.57233,170.4249Q429.82451,250,411.71818,339.87391Z"
                      ></animate>
                    </path>
                  </svg>
                  <div className="services__row__blob__number">77%</div>
                  <div className="services__row__blob__text">Narrow target audience analysis</div>
                </div>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  width="100%"
                  id="blobSvg"
                  style={{ opacity: 1 }}
                  className="services__row__blob__additional__first"
                >
                  <defs>
                    <linearGradient id="gradient4" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{ stopColor: "rgb(120, 227, 126)" }}></stop>
                      <stop offset="100%" style={{ stopColor: "rgb(0, 135, 46)" }}></stop>
                    </linearGradient>
                  </defs>

                  <path id="blob" fill="url(#gradient4)">
                    <animate
                      attributeName="d"
                      dur="15s"
                      repeatCount="indefinite"
                      values="M423.42552,332.41134Q414.82268,414.82268,332.41134,424.30554Q250,433.78841,170.96572,420.92848Q91.93144,408.06856,46.07152,329.03428Q0.21159,250,66.88003,191.77423Q133.54846,133.54846,191.77423,102.82861Q250,72.10876,305.00592,106.04846Q360.01185,139.98815,396.0201,194.99408Q432.02836,250,423.42552,332.41134Z;M418.08664,320.33435Q390.6687,390.6687,320.33435,427.91946Q250,465.17023,188.27506,419.31005Q126.55013,373.44987,106.38448,311.72494Q86.21883,250,84.09726,165.98785Q81.9757,81.9757,165.98785,53.98938Q250,26.00305,311.1687,76.83282Q372.3374,127.6626,408.92099,188.8313Q445.50458,250,418.08664,320.33435Z;M395.5,320Q390,390,320,400Q250,410,172,408Q94,406,59,328Q24,250,70.5,183.5Q117,117,183.5,108Q250,99,335,89.5Q420,80,410.5,165Q401,250,395.5,320Z;M423.42552,332.41134Q414.82268,414.82268,332.41134,424.30554Q250,433.78841,170.96572,420.92848Q91.93144,408.06856,46.07152,329.03428Q0.21159,250,66.88003,191.77423Q133.54846,133.54846,191.77423,102.82861Q250,72.10876,305.00592,106.04846Q360.01185,139.98815,396.0201,194.99408Q432.02836,250,423.42552,332.41134Z"
                    ></animate>
                  </path>
                </svg>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  width="100%"
                  id="blobSvg"
                  className="services__row__blob__additional__second"
                >
                  <defs>
                    <linearGradient id="gradient5" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)" }}></stop>
                      <stop offset="100%" style={{ stopColor: "rgb(0, 163, 255)" }}></stop>
                    </linearGradient>
                  </defs>

                  <path id="blob" fill="url(#gradient5)">
                    <animate
                      attributeName="d"
                      dur="15s"
                      repeatCount="indefinite"
                      values="M420.7122,331.4339Q380.09423,412.86779,301.16508,392.29219Q222.23594,371.7166,157.11322,349.24982Q91.99051,326.78305,53.64135,232.38203Q15.29219,137.98101,118.09898,129.47187Q220.90577,120.96273,294.67458,111.25967Q368.44339,101.55661,414.88678,175.77831Q461.33017,250,420.7122,331.4339Z;M392.2525,331.53996Q379.05994,413.07992,285.7525,456.15485Q192.44505,499.22978,100.80019,432.44731Q9.15533,365.66484,30.81018,260.57493Q52.46503,155.48501,121.45005,74.10764Q190.43506,-7.26974,268.13261,60.97003Q345.83016,129.2098,375.63761,189.6049Q405.44505,250,392.2525,331.53996Z;M384.02639,318.52969Q359.04619,387.05939,278.02474,435.01815Q197.0033,482.97691,137.94391,408.01485Q78.88453,333.05279,75.91422,249.0132Q72.94391,164.97361,138.46041,107.43731Q203.97691,49.90102,276.0132,88.41917Q348.04949,126.93731,378.52804,188.46866Q409.0066,250,384.02639,318.52969Z;M420.7122,331.4339Q380.09423,412.86779,301.16508,392.29219Q222.23594,371.7166,157.11322,349.24982Q91.99051,326.78305,53.64135,232.38203Q15.29219,137.98101,118.09898,129.47187Q220.90577,120.96273,294.67458,111.25967Q368.44339,101.55661,414.88678,175.77831Q461.33017,250,420.7122,331.4339Z"
                    ></animate>
                  </path>
                </svg>

                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  width="100%"
                  id="blobSvg"
                  className="services__row__blob__additional__third"
                >
                  <defs>
                    <linearGradient id="gradient6" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{ stopColor: "rgb(255, 0, 0)" }}></stop>
                      <stop offset="100%" style={{ stopColor: "rgb(255, 168, 1)" }}></stop>
                    </linearGradient>
                  </defs>

                  <path id="blob" fill="url(#gradient6)">
                    <animate
                      attributeName="d"
                      dur="15s"
                      repeatCount="indefinite"
                      values="M420.7122,331.4339Q380.09423,412.86779,301.16508,392.29219Q222.23594,371.7166,157.11322,349.24982Q91.99051,326.78305,53.64135,232.38203Q15.29219,137.98101,118.09898,129.47187Q220.90577,120.96273,294.67458,111.25967Q368.44339,101.55661,414.88678,175.77831Q461.33017,250,420.7122,331.4339Z;M392.2525,331.53996Q379.05994,413.07992,285.7525,456.15485Q192.44505,499.22978,100.80019,432.44731Q9.15533,365.66484,30.81018,260.57493Q52.46503,155.48501,121.45005,74.10764Q190.43506,-7.26974,268.13261,60.97003Q345.83016,129.2098,375.63761,189.6049Q405.44505,250,392.2525,331.53996Z;M384.02639,318.52969Q359.04619,387.05939,278.02474,435.01815Q197.0033,482.97691,137.94391,408.01485Q78.88453,333.05279,75.91422,249.0132Q72.94391,164.97361,138.46041,107.43731Q203.97691,49.90102,276.0132,88.41917Q348.04949,126.93731,378.52804,188.46866Q409.0066,250,384.02639,318.52969Z;M420.7122,331.4339Q380.09423,412.86779,301.16508,392.29219Q222.23594,371.7166,157.11322,349.24982Q91.99051,326.78305,53.64135,232.38203Q15.29219,137.98101,118.09898,129.47187Q220.90577,120.96273,294.67458,111.25967Q368.44339,101.55661,414.88678,175.77831Q461.33017,250,420.7122,331.4339Z"
                    ></animate>
                  </path>
                </svg>
              </div>
            </div>
          </div>
        </Container>
        <div
          className="services__button__container"
          onClick={() => SmoothScrollbar.get(appRef.current).scrollTo(0, contactRef.current.offsetTop - 150, 700)}
        >
          <div className="services__button">Contact us</div>
        </div>
      </div>
    </section>
  );
});
